import React from 'react'

const MonitorTaskAddContainer = Loader.loadModuleComponent('MonitorTasks', 'MonitorTaskAddContainer')

const EventMonitorTaskAdd = () => (
  <MonitorTaskAddContainer 
    libType={3}
    taskLabel='人员入侵'
    taskType='101503'
    alarmThreshold={undefined}
    taskListModuleName='eventTaskView'
  />
)
 
export default EventMonitorTaskAdd;